<template>
  <div>
    <app-header />

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'public.home' }">Home</router-link>
              </li>
              <li><span>Contact Us</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
      "
    >
      <div class="uk-container uk-margin-top uk-background-default uk-padding">

        <contact-us />
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import ContactUs from '../shared/ContactUs.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
    ContactUs,
  },
};
</script>
